exports.components = {
  "component---src-components-layouts-contribution-template-js": () => import("./../../../src/components/Layouts/contributionTemplate.js" /* webpackChunkName: "component---src-components-layouts-contribution-template-js" */),
  "component---src-pages-comments-de-js": () => import("./../../../src/pages/comments.de.js" /* webpackChunkName: "component---src-pages-comments-de-js" */),
  "component---src-pages-comments-en-js": () => import("./../../../src/pages/comments.en.js" /* webpackChunkName: "component---src-pages-comments-en-js" */),
  "component---src-pages-comments-it-js": () => import("./../../../src/pages/comments.it.js" /* webpackChunkName: "component---src-pages-comments-it-js" */),
  "component---src-pages-comments-js": () => import("./../../../src/pages/comments.js" /* webpackChunkName: "component---src-pages-comments-js" */),
  "component---src-pages-comments-pt-js": () => import("./../../../src/pages/comments.pt.js" /* webpackChunkName: "component---src-pages-comments-pt-js" */),
  "component---src-pages-contact-de-js": () => import("./../../../src/pages/contact.de.js" /* webpackChunkName: "component---src-pages-contact-de-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-es-js": () => import("./../../../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-contact-pt-js": () => import("./../../../src/pages/contact.pt.js" /* webpackChunkName: "component---src-pages-contact-pt-js" */),
  "component---src-pages-contribution-form-js": () => import("./../../../src/pages/contributionForm.js" /* webpackChunkName: "component---src-pages-contribution-form-js" */),
  "component---src-pages-contribution-js": () => import("./../../../src/pages/contribution.js" /* webpackChunkName: "component---src-pages-contribution-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-upload-js": () => import("./../../../src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */)
}

